import React from 'react';
import {
  ComponentPreview,
  PageHero,
  PageNavigation,
  PageNavigationLink,
  PageWithSubNav,
  Paragraph,
  Section,
} from 'docComponents';
import { Textarea } from 'hudl-uniform-ui-components';
import SectionSize from '../partials/_SectionSize';
import SectionUsage from '../partials/_SectionUsage';
import SectionPlatform from '../partials/_SectionPlatform';
import SectionMicroLabels from '../partials/_SectionMicroLabels';
import SectionMicroPlaceholders from '../partials/_SectionMicroPlaceholders';
import SectionMicroHelpText from '../partials/_SectionMicroHelpText';
import inputPreviewData from '../../../../data/previews/input.yml';
import pageHeroData from '../../../../data/pages/components.yml';

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Text Area"
      designCode
      subnav="components">
      <PageHero heroData={pageHeroData} tierTwo="Forms" tierThree="Text Area" />

      <PageNavigation>
        <PageNavigationLink>Size</PageNavigationLink>
        <PageNavigationLink>State</PageNavigationLink>
        <PageNavigationLink>Usage</PageNavigationLink>
        <PageNavigationLink>Microcopy</PageNavigationLink>
        <PageNavigationLink>Platform</PageNavigationLink>
      </PageNavigation>

      <SectionSize text="Text area" />

      <Section title="State">
        <Paragraph>
          A text area’s state depends on the interaction itself or a submission
          error.
        </Paragraph>
        <ComponentPreview
          name="TextareaState"
          previewData={inputPreviewData.state}>
          <Textarea
            label="Additional Feedback"
            placeholder="We want to know what went well this season and what could use more work"
            helpText="Max. 500 words"
            rows={8}
          />
        </ComponentPreview>
      </Section>

      <SectionUsage hasLabel />

      <Section title="Microcopy">
        <SectionMicroLabels imgName="textarea-label" />
        <SectionMicroPlaceholders imgName="textarea-placeholder" />
        <SectionMicroHelpText imgName="textarea-helptext" />
      </Section>

      <SectionPlatform />
    </PageWithSubNav>
  );
};

export default IndexPage;
